import React, { useState, useEffect } from 'react';
import { Container, Col, Form, FormGroup, Label, Input, Button, Alert, Jumbotron } from 'reactstrap';
import * as Yup from 'yup';
import api from '../../components/api';
import history from '../../components/history';

// import { Container } from './styles';

const schema = Yup.object().shape({
	name: Yup.string().required(),
	contact_name: Yup.string().required(),
	contact_email: Yup.string().required(),
	direct_shipping: Yup.boolean().required(),
	status: Yup.boolean().required(),
});

export default function PartnersEdit({ location }) {
	const { id } = location.state;

	const [message, setMessage] = useState('');
	const [inputs, setInputs] = useState({
		name: '',
		contact_name: '',
		contact_email: '',
		direct_shipping: '',
		status: '',
	});
	const [visible, setVisible] = useState(false);

	const onDismiss = () => setVisible(false);

	const handleCancelBtn = () => {
		history.push('/partners');
	};

	const handleInputChange = e => {
		e.persist();

		setInputs(data => ({ ...data, [e.target.name]: e.target.value }));
	};

	const handleSubmit = async e => {
		e.preventDefault();

		const formIsValid = await schema.isValid(inputs);

		if (!formIsValid) {
			setMessage('Preencha corretamente os dados do formulário');
			setVisible(true);
			return false;
		}

		const partner = await api.put(`/partners/${id}`, inputs);

		if (partner.data.message) {
			setMessage(partner.data.message);
			setVisible(true);
			return false;
		}

		history.push('/partners');

		return true;
	};

	useEffect(() => {
		const exec = async () => {
			const registry = await api.get('/partners', { params: { id } });

			const { name, contact_name, contact_email, direct_shipping, status } = registry.data;
			setInputs(() => ({ name, contact_name, contact_email, direct_shipping, status }));
		};

		exec();
	}, [id]);

	return (
		<Container className="addPartner" onSubmit={handleSubmit}>
			<Jumbotron className="my-4">
				<h5 className="display-5">Editar parceiro</h5>
				<hr className="my-2" />
				<Form className="form">
					<Alert color="danger" isOpen={visible} toggle={onDismiss} fade={false}>
						{message}
					</Alert>
					<Col>
						<FormGroup>
							<Label>Nome</Label>
							<Input type="text" name="name" value={inputs.name} onChange={handleInputChange} />
						</FormGroup>
					</Col>
					<Col>
						<hr />
					</Col>
					<Col>
						<FormGroup>
							<Label>Produtos enviados diretamente pelo parceiro</Label>
							<Input type="select" name="direct_shipping" value={inputs.direct_shipping} onChange={handleInputChange}>
								<option value={false}>Não</option>
								<option value>Sim</option>
							</Input>
						</FormGroup>
					</Col>
					<Col>
						<FormGroup>
							<Label>Contato</Label>
							<Input type="text" name="contact_name" value={inputs.contact_name} onChange={handleInputChange} />
						</FormGroup>
					</Col>
					<Col>
						<FormGroup>
							<Label>Email</Label>
							<Input type="text" name="contact_email" value={inputs.contact_email} onChange={handleInputChange} />
						</FormGroup>
					</Col>
					<Col>
						<hr />
					</Col>
					<Col>
						<FormGroup>
							<Label>Status</Label>
							<Input type="select" name="status" value={inputs.status} onChange={handleInputChange}>
								<option value={false}>Inativo</option>
								<option value>Ativo</option>
							</Input>
						</FormGroup>
					</Col>
					<Button color="primary">Salvar</Button>{' '}
					<Button color="secondary" type="button" onClick={handleCancelBtn}>
						Cancelar
					</Button>
				</Form>
			</Jumbotron>
		</Container>
	);
}
