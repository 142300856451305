import React, { useState, useEffect } from 'react';
import { Table, Button } from 'reactstrap';
import { format } from 'date-fns';
import api from '../../components/api';
import history from '../../components/history';

import './styles.css';

export default function NotificationsPush({ location }) {
	const { user, building } = location.state ? location.state : {};

	const [updateList, setUpdateList] = useState(0);

	const [list, setList] = useState({
		tokens: [],
	});

	const handleSendPushNotificationIdBtn = async e => {
		if (window.confirm(`Deseja realmente enviar uma notificação para o usuário ${e.target.getAttribute('data-user-name')}?`)) {
			const message = window.prompt('Digite a menssagem:');

			if (message) {
				const retorno = await api.post(`/notifications/push`, { id: e.target.getAttribute('data-id'), message });

				if (retorno) {
					window.alert(retorno.data.message);
				}
			}
		}
	};

	const handleSendPushForAllListBtn = async e => {
		if (window.confirm(`Deseja realmente programar uma notificação para todos dessa lista?`)) {
			const message = window.prompt('Digite a menssagem:');

			if (message) {
				const retorno = await api.post(`/notifications/push`, building ? { building, message } : { all: true, message });

				if (retorno) {
					window.alert(retorno.data.message);
					history.push('/notifications/push/scheduled');
				}
			}
		}
	};

	const handleSendPushForMinBalanceBtn = async e => {
		const min_balance = window.prompt('Digite um valor mínimo de saldo:');

		if (isNaN(parseInt(min_balance))) return window.alert('Valor inválido');

		if (window.confirm(`Deseja realmente programar uma notificação para todos os usúsarios que tenham no mínimo ${min_balance} pontos?`)) {
			const message = window.prompt('Digite a menssagem:');

			if (message) {
				const retorno = await api.post(`/notifications/push`, { message, min_balance });

				if (retorno) {
					window.alert(retorno.data.message);
					history.push('/notifications/push/scheduled');
				}
			}
		}
	};

	const handleRemoveBtn = async e => {
		if (window.confirm(`Deseja realmente remover o token do usuário ${e.target.getAttribute('data-user-name')}?`)) {
			await api.delete(`/push-token/${e.target.getAttribute('data-id')}`, {});

			setUpdateList(() => updateList + 1);
		}
	};

	useEffect(() => {
		const getTokens = async () => {
			console.log({ user, building });
			const tokens = await api.get('/push-token', { params: { user, building } });

			setList(() => ({ tokens: tokens.data }));
		};

		getTokens();
	}, [building, updateList, user]);

	return (
		<>
			<Table striped>
				<thead>
					<tr>
						<th>Usuários</th>
						<th>Pontos</th>
						<th>Condomínio</th>
						<th>Dispositivo</th>
						<th>Atualizado em</th>
						<th>
							{list.tokens.length > 0 && !user ? (
								<Button color="success" size="sm" onClick={handleSendPushForAllListBtn}>
									Enviar notificação para todos da lista
								</Button>
							) : null}
						</th>
						<th>
							{list.tokens.length > 0 ? (
								<Button color="success" size="sm" onClick={handleSendPushForMinBalanceBtn}>
									Enviar notificação por qtd mínima de pontos
								</Button>
							) : null}
						</th>
					</tr>
				</thead>
				<tbody>
					{list.tokens.map(token => (
						<tr key={token._id}>
							<th scope="row">{token.user ? token.user.name : null}</th>
							<th scope="row">{token.user ? token.user.balance : null}</th>
							<th scope="row">{token.building ? token.building.name : null}</th>
							<th scope="row">{token.platform_os}</th>
							<th scope="row">{format(new Date(token.updatedAt), 'dd/MM/yyyy H:mm')}</th>
							<td>
								<Button color="primary" size="sm" data-id={token._id} data-user-name={token.user ? token.user.name : null} onClick={handleSendPushNotificationIdBtn}>
									Enviar notificação
								</Button>
							</td>
							<td>
								<Button color="danger" size="sm" data-id={token._id} data-user-name={token.user ? token.user.name : null} onClick={handleRemoveBtn}>
									Remover
								</Button>
							</td>
						</tr>
					))}
					{list.tokens.length === 0 ? (
						<tr>
							<td colSpan="5">
								<div className="text-center">
									{user ? 'O usuário não deu permissão para envio de notificações' : null}
									{building ? 'Não há nenhum usuário nesse condomínio com permissão para envio de notificações' : null}
								</div>
							</td>
						</tr>
					) : null}
				</tbody>
			</Table>
		</>
	);
}
