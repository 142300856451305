import React, { useState, useEffect } from 'react';
import { Container, Col, Form, FormGroup, Label, Input, Button, Alert, Jumbotron } from 'reactstrap';
import * as Yup from 'yup';
import api from '../../components/api';
import history from '../../components/history';

// import { Container } from './styles';

const schema = Yup.object().shape({
  name: Yup.string().required(),
  cnpj: Yup.string().required(),
  max_apartments: Yup.number().required(),
  city: Yup.string().required(),
});

export default function BuildingsEdit({ location }) {
  const { id } = location.state;

  const [message, setMessage] = useState('');
  const [cities, setCities] = useState([]);
  const [inputs, setInputs] = useState({
    name: '',
    cnpj: '',
    max_apartments: '',
    report: null,
    announcement: null,
    city: '',
  });
  const [list, setList] = useState({
    building_blocks: [],
  });
  const [visible, setVisible] = useState(false);

  const onDismiss = () => setVisible(false);

  const handleCancelBtn = () => {
    history.push('/buildings');
  };

  const handleAddBlockClick = async () => {
    if (!inputs.max_apartments) alert('Informe o numero maxixo de partamentos por bloco');

    const block = await api.post('/building-blocks', {
      name: inputs.add_block,
      max_apartments: inputs.max_apartments,
      building: id,
    });

    setList(() => ({
      building_blocks: [...list.building_blocks, block.data],
    }));

    setInputs(data => ({ ...data, add_block: '' }));
  };

  const handleInputChange = e => {
    e.persist();
    if (e.target.type === 'file') {
      setInputs(data => ({
        ...data,
        [e.target.name]: e.target.files[0],
      }));
      return;
    }

    setInputs(data => ({ ...data, [e.target.name]: e.target.value }));
  };

  const handleDeleteBlock = async e => {
    e.persist();

    await api.delete(`/buildings/${e.target.getAttribute('block_id')}`);

    setList(() => ({
      building_blocks: [...list.building_blocks.filter(value => value._id !== e.target.getAttribute('block_id'))],
    }));
  };

  const handleSubmit = async e => {
    e.preventDefault();

    const formIsValid = await schema.isValid(inputs);

    if (!formIsValid) {
      setMessage('Preencha corretamente os dados do formulário');
      setVisible(true);
      return false;
    }

    const formData = new FormData();
    formData.append('name', inputs.name);
    formData.append('cnpj', inputs.cnpj);
    formData.append('city', inputs.city);
    formData.append('max_apartments', inputs.max_apartments);
    if (inputs.report) formData.append('report', inputs.report);
    if (inputs.announcement) formData.append('announcement', inputs.announcement);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    const building = await api.put(`/buildings/${id}`, formData, config);

    if (building.data.message) {
      setMessage(building.data.message);
      setVisible(true);
      return false;
    }

    history.push('/buildings');

    return true;
  };

  useEffect(() => {
    const exec = async () => {
      const registry = await api.get('/buildings', { params: { id } });

      const cityList = await api.get('/cities');
      setCities(() => cityList.data);

      setInputs(() => ({
        name: registry.data.name,
        cnpj: registry.data.cnpj,
        max_apartments: registry.data.max_apartments,
        city: registry.data.city ? registry.data.city : '',
      }));

      const blocks = await api.get('/building-blocks', {
        params: { building: id },
      });

      setList(() => ({
        building_blocks: [...blocks.data],
      }));
    };

    exec();
  }, [id]);

  return (
    <Container className="addBuilding" onSubmit={handleSubmit}>
      <Jumbotron className="my-4">
        <h5 className="display-5">Editar condomínio</h5>
        <hr className="my-2" />
        <Form className="form">
          <Alert color="danger" isOpen={visible} toggle={onDismiss} fade={false}>
            {message}
          </Alert>
          <Col>
            <FormGroup>
              <Label>Nome</Label>
              <Input type="text" name="name" value={inputs.name} onChange={handleInputChange} />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>CNPJ</Label>
              <Input type="text" name="cnpj" value={inputs.cnpj} onChange={handleInputChange} />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>Cidade</Label>
              <Input type="select" name="city" value={inputs.city} onChange={handleInputChange}>
                <option value="">Escolha...</option>
                {cities.map(item => (
                  <option value={item._id} key={item._id}>
                    {item.name}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>Quantidade de apartamentos por bloco</Label>
              <Input type="text" name="max_apartments" value={inputs.max_apartments} onChange={handleInputChange} />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>Bloco</Label>
              <Input type="text" name="add_block" value={inputs.add_block} onChange={handleInputChange} />
              <Button color="primary" type="button" onClick={handleAddBlockClick}>
                Adicionar
              </Button>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              {list.building_blocks.map(block => (
                <p key={block._id}>
                  <Button color="secondary" type="button" onClick={handleDeleteBlock} block_id={block._id}>
                    x
                  </Button>
                  {block.name}
                </p>
              ))}
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>Relátorio do mês</Label>
              <Input type="file" name="report" onChange={handleInputChange} />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>Comunicado</Label>
              <Input type="file" name="announcement" onChange={handleInputChange} />
            </FormGroup>
          </Col>
          <Button color="primary">Salvar</Button>{' '}
          <Button color="secondary" type="button" onClick={handleCancelBtn}>
            Cancelar
          </Button>
        </Form>
      </Jumbotron>
    </Container>
  );
}
