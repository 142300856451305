import React from 'react';
import PropTypes from 'prop-types';
import AdmNavbar from '../../components/AdmNavbar';

import { Wrapper, SubWrapper } from './styles';

export default function DefaultLayout({ children }) {
	return (
		<Wrapper>
			<AdmNavbar />
			<SubWrapper>{children}</SubWrapper>
		</Wrapper>
	);
}

DefaultLayout.propTypes = {
	children: PropTypes.element.isRequired,
};
