import styled from 'styled-components';

export const Wrapper = styled.div`
	height: 100%;
`;

export const SubWrapper = styled.div`
	height: 100%;
	padding: 30px;
`;
