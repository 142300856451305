import React, { useState, useEffect } from 'react';
import { Table, h2 } from 'reactstrap';
import { format } from 'date-fns';
import api from '../../components/api';

export default function UsersHistory({ location }) {
	const { id } = location.state;
	const [cashierList, setCashierList] = useState({
		cashiers: [],
	});

	const [user, setUser] = useState({});

	useEffect(() => {
		const exec = async () => {
			const cashierGetList = await api.get('/cashier', { params: { user_id: id } });

			setCashierList(() => ({
				cashiers: cashierGetList.data,
			}));

			const getUser = await api.get('/users', { params: { id } });

			setUser(() => getUser.data);
		};

		exec();
	}, [id]);

	return (
		<>
			<h2>Histórico de pontos</h2>

			<Table striped>
				<thead>
					<tr>
						<th>{user.name}</th>
						<th>Saldo: {user.balance}</th>
					</tr>
				</thead>
			</Table>

			<Table striped>
				<thead>
					<tr>
						<th>Data</th>
						<th>Operação</th>
						<th>Pontos</th>
						<th>&nbsp;</th>
					</tr>
				</thead>
				<tbody>
					{cashierList.cashiers ? (
						cashierList.cashiers.map(row => (
							<tr key={row._id}>
								<th scope="row">{format(new Date(row.createdAt), 'dd/MM/yyyy H:mm')}</th>
								<th scope="row">{row.transaction_type === 'credit' ? 'Crédito' : 'Débito'}</th>
								<th scope="row">
									{row.transaction_type === 'debit' ? '-' : ''}
									{row.value}
								</th>
							</tr>
						))
					) : (
						<></>
					)}
				</tbody>
			</Table>
		</>
	);
}
