import React, { useState, useEffect } from 'react';
import { Table, Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import api from '../../components/api';
import history from '../../components/history';

// import { Container } from './styles';

export default function NotificationsPushScheduled() {
  const [updateList, setUpdateList] = useState(0);

  const [list, setList] = useState({
    notifications: [],
  });

  const getNotifications = async () => {
    const notifications = await api.get('/notifications/push', {});

    setList(() => ({ notifications: notifications.data }));
  };

  const handleSendBtn = async e => {
    e.target.setAttribute('disabled', true);
    const id = e.target.getAttribute('data-id');
    const approve = e.target.getAttribute('data-approve');

    if (approve) {
      const approve_code = prompt('Digite o código de aprovação para enviar a notificação');

      if (!approve_code) {
        toast.error('Código de aprovação inválido');
        return false;
      }

      const approved = await api.put(`/notifications/push/${id}`, { approve_code });

      if (approved.data.error) {
        toast.error(approved.data.error);
        return false;
      }

      setUpdateList(() => updateList + 1);

      toast.success('Notificação enviada com sucesso!');
      return true;
    }

    const sent = await api.put(`/notifications/push/${id}`, {});

    if (sent.data.error) {
      toast.error(sent.data.error);
      return false;
    }

    setUpdateList(() => updateList + 1);

    toast.success('Notificação enviada com sucesso!');
    return true;
  };

  const handleAddBtn = () => {
    history.push('/notifications/push');
  };

  useEffect(() => {
    getNotifications();
  }, [updateList]);

  return (
    <>
      <Table striped>
        <thead>
          <tr>
            <th>Notificações Push progamadas</th>
            <th>Qtd usuários</th>
            <th>Requer código de aprovação</th>
            <th>Enviado em</th>
            <th>
              <Button color="success" size="sm" onClick={handleAddBtn}>
                Adicionar
              </Button>
            </th>
          </tr>
        </thead>
        <tbody>
          {list.notifications.map(notification => (
            <tr key={notification._id}>
              <th scope="row">{notification.message}</th>
              <th scope="row">{notification.qtd_users}</th>
              <th scope="row">{notification.approve_code ? 'Sim' : 'Não'}</th>
              <th scope="row">{notification.sent ? format(new Date(notification.updatedAt), 'dd/MM/yyyy H:mm') : '-'}</th>
              <td>
                {!notification.sent ? (
                  <Button color="primary" size="sm" data-id={notification._id} data-approve={notification.approve_code} onClick={handleSendBtn}>
                    Enviar
                  </Button>
                ) : null}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}
