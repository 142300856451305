import React, { useState, useEffect } from 'react';
import { Table, Button } from 'reactstrap';
import api from '../../components/api';
import history from '../../components/history';

// import { Container } from './styles';

export default function Cities() {
	const [updateList, setUpdateList] = useState(0);

	const [list, setList] = useState({
		cities: [],
	});

	const getCities = async () => {
		const cities = await api.get('/cities', {});

		setList(() => ({ cities: cities.data }));
	};

	const handleAddBtn = () => {
		history.push('/cities/add');
	};

	const handleEditBtn = e => {
		history.push('/cities/edit', {
			id: e.target.getAttribute('data-id'),
		});
	};

	const handleRemoveBtn = async e => {
		if (window.confirm('Deseja realmente remover essa cidade?')) {
			await api.delete(`/cities/${e.target.getAttribute('data-id')}`, {});

			setUpdateList(() => updateList + 1);
		}
	};

	useEffect(() => {
		getCities();
	}, [updateList]);

	return (
		<>
			<Table striped>
				<thead>
					<tr>
						<th>Cidade</th>
						<th>&nbsp;</th>
						<th>
							<Button color="success" size="sm" onClick={handleAddBtn}>
								Adicionar
							</Button>
						</th>
					</tr>
				</thead>
				<tbody>
					{list.cities.map(cities => (
						<tr key={cities._id}>
							<th scope="row">{cities.name}</th>
							<td>
								<Button color="primary" size="sm" data-id={cities._id} onClick={handleEditBtn}>
									Editar
								</Button>
							</td>
							<td>
								<Button color="danger" size="sm" data-id={cities._id} onClick={handleRemoveBtn}>
									Remover
								</Button>
							</td>
						</tr>
					))}
				</tbody>
			</Table>
		</>
	);
}
