import React, { useState, useEffect } from 'react';
import { Container, Col, Form, FormGroup, Label, Input, Button, Alert, Jumbotron, Media } from 'reactstrap';
import * as Yup from 'yup';
import { format } from 'date-fns';
import api from '../../components/api';
import configs from '../../components/configs';
import history from '../../components/history';

// import { Container } from './styles';

const schema = Yup.object().shape({
  status: Yup.string().required(),
});

export default function OrderEdit({ location }) {
  const { id } = location.state;

  const [message, setMessage] = useState('');
  const [order, setOrder] = useState({
    product: { name: '' },
    user: { name: '', email: '', apartment: '', building: { name: '' }, building_block: { name: '' } },
    status: '',
    createdAt: new Date(),
    coupon: { code: '', validity: '' },
  });
  const [inputs, setInputs] = useState({
    status: '',
  });
  const [visible, setVisible] = useState(false);

  const onDismiss = () => setVisible(false);

  const handleCancelBtn = () => {
    history.push('/orders');
  };

  const handleInputChange = e => {
    e.persist();
    if (e.target.type === 'file') {
      setInputs(data => ({
        ...data,
        [e.target.name]: e.target.files[0],
      }));
      return;
    }

    setInputs(data => ({ ...data, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async e => {
    e.preventDefault();

    const formIsValid = await schema.isValid(inputs);

    if (!formIsValid) {
      setMessage('Preencha corretamente os dados do formulário');
      setVisible(true);
      return false;
    }

    const registry = await api.put(`/orders/${id}`, inputs);

    if (registry.data.message) {
      setMessage(registry.data.message);
      setVisible(true);
      return false;
    }

    history.push('/orders');

    return true;
  };

  const handleReenviarEmailBtn = async () => {
    setMessage('Enviando email, aguarde...');
    setVisible(true);

    const registry = await api.put(`/orders-resend-email/${id}`);

    if (registry.data.message) {
      setMessage(registry.data.message);
      setVisible(true);
      return false;
    }

    setMessage('Email reenviado com sucesso');
    setVisible(true);

    return true;
  };

  useEffect(() => {
    const exec = async () => {
      const registry = await api.get('/orders', { params: { id } });

      setInputs(() => ({ status: registry.data.status }));

      setOrder(() => ({ ...registry.data }));
    };

    exec();
  }, [id]);

  return (
    <Container className="editorder" onSubmit={handleSubmit}>
      <Jumbotron className="my-4">
        <Form className="form">
          <Alert color="danger" isOpen={visible} toggle={onDismiss} fade={false}>
            {message}
          </Alert>
          <h5 className="display-5">
            Pedido <span className="float-right">{format(new Date(order.createdAt), 'dd/MM/yyyy  H:mm')}</span>
          </h5>
          <hr className="my-2" />
          <Media>
            <Media left href="#">
              {order && order.product && 'photo' in order.product ? <img src={`${configs.apiURL}/files/${order.product.photo}`} width="100" alt="Foto atual" /> : ''}
            </Media>
            <Media body>
              <Media className="display-6 ml-1">{order.product ? order.product.name : '-'}</Media>
              {order.coupon && order.coupon.code && order.coupon.validity ? (
                <>
                  <Media className="display-6 ml-1">Cupom: {order.coupon.code}</Media>
                  <Media className="display-6 ml-1">Validade: {format(new Date(order.coupon.validity), 'dd/MM/yyyy')}</Media>
                  <br />
                  <Button color="primary" type="button" onClick={handleReenviarEmailBtn}>
                    Reenviar email com o cupom
                  </Button>
                </>
              ) : (
                <></>
              )}
            </Media>
          </Media>
          <br />
          <br />
          <h5 className="display-5">Usuário</h5>
          <hr className="my-2" />
          <address>
            <strong>{order.user.name}</strong>
            <br />
            <a href="mailto:{order.user.email}">{order.user.email}</a>
          </address>
          <address>
            <strong>{order.user.building.name}</strong>
            <br />
            <abbr title="Apartamento">Ap:</abbr> {order.user.apartment}
            <br />
            {order.user.building_block && order.user.building_block.name ? (
              <>
                <abbr title="Bloco">Bloco:</abbr> {order.user.building_block.name}
              </>
            ) : (
              <></>
            )}
          </address>
          <br />
          <br />
          <h5 className="display-5">Status do pedido</h5>
          <hr className="my-2" />
          <Input type="select" name="status" value={inputs.status} onChange={handleInputChange}>
            <option value="processing">Processando</option>
            <option value="sended">Enviado</option>
            <option value="canceled">Cancelado</option>
          </Input>
          <br />
          <br />
          <Button color="primary">Salvar</Button>{' '}
          <Button color="secondary" type="button" onClick={handleCancelBtn}>
            Voltar
          </Button>
        </Form>
      </Jumbotron>
    </Container>
  );
}
