import React, { useState, useEffect } from 'react';
import { Table, Button } from 'reactstrap';
import api from '../../components/api';
import history from '../../components/history';
import configs from '../../components/configs';

// import { Container } from './styles';

export default function Buildings() {
  const [updateList, setUpdateList] = useState(0);

  const [list, setList] = useState({
    buildings: [],
  });

  const getBuildings = async () => {
    const buildings = await api.get('/buildings', {});

    setList(() => ({ buildings: buildings.data }));
  };

  const handleAddBtn = () => {
    history.push('/buildings/add');
  };

  const handleAnnouncementBtn = () => {
    history.push('/buildings/announcement');
  };

  const handleEditBtn = e => {
    history.push('/buildings/edit', {
      id: e.target.getAttribute('data-id'),
    });
  };

  const handlePushNotificationBtn = e => {
    history.push('/notifications/push', {
      building: e.target.getAttribute('data-id'),
    });
  };

  const handleRemoveBtn = async e => {
    if (window.confirm('Deseja realmente remover esse condomínio?')) {
      await api.delete(`/buildings/${e.target.getAttribute('data-id')}`, {});

      setUpdateList(() => updateList + 1);
    }
  };

  useEffect(() => {
    getBuildings();
  }, [updateList]);

  return (
    <>
      <Table striped>
        <thead>
          <tr>
            <th>Condomínios</th>
            <th>CNPJ</th>
            <th>Cidade</th>
            <th>Relatório</th>
            <th>Comunicado</th>
            <th>&nbsp;</th>
            <th>
              <Button color="success" size="sm" onClick={handleAnnouncementBtn}>
                Publicar comunicado
              </Button>
            </th>
            <th>
              <Button color="success" size="sm" onClick={handleAddBtn}>
                Adicionar
              </Button>
            </th>
          </tr>
        </thead>
        <tbody>
          {list.buildings.map(build => (
            <tr key={build._id}>
              <th scope="row">{build.name}</th>
              <th scope="row">{build.cnpj ? build.cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5') : '-'}</th>
              <th scope="row">{build.city.name}</th>
              <th scope="row">
                <a href={`${configs.apiURL}/files/${build.report}`} target="_blank" rel="noopener noreferrer" style={{ fontSize: 12 }}>
                  (Ver)
                </a>{' '}
                {build.report_original_name}
              </th>
              <th scope="row">
                <a href={`${configs.apiURL}/files/${build.announcement} `} target="_blank" rel="noopener noreferrer" style={{ fontSize: 12 }}>
                  (Ver)
                </a>{' '}
                {build.announcement_original_name}
              </th>
              <td>
                <Button color="primary" size="sm" data-id={build._id} onClick={handleEditBtn}>
                  Editar
                </Button>
              </td>
              <td>
                <Button color="primary" size="sm" data-id={build._id} onClick={handlePushNotificationBtn}>
                  Enviar notificação
                </Button>
              </td>
              <td>
                <Button color="danger" size="sm" data-id={build._id} onClick={handleRemoveBtn}>
                  Remover
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}
