import React, { useState, useEffect, useCallback } from 'react';
import { Table, Button } from 'reactstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import api from '../../components/api';
import history from '../../components/history';
import moveIcon from '../../assets/move.svg';

// import { Container } from './styles';

export default function Products() {
	const [updateList, setUpdateList] = useState(0);

	const [list, setList] = useState({
		products: [],
	});

	const getProducts = async () => {
		const products = await api.get('/products', {});

		setList(() => ({ products: products.data }));
	};

	const handleAddBtn = () => {
		history.push('/products/add');
	};

	const handleEditBtn = e => {
		history.push('/products/edit', {
			id: e.target.getAttribute('data-id'),
		});
	};

	const handleCuponsBtn = e => {
		history.push('/products/coupons', {
			id: e.target.getAttribute('data-id'),
		});
	};

	const handleRemoveBtn = async e => {
		if (window.confirm('Deseja realmente remover esse produto?')) {
			await api.delete(`/products/${e.target.getAttribute('data-id')}`, {});

			setUpdateList(() => updateList + 1);
		}
	};

	const handleUpdateOrder = async (id, order, direction) => {
		await api.put(`/products-order/${id}`, { order, direction });
	};

	const reorder = (list, startIndex, endIndex) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);

		return result;
	};

	const onItemDragEnd = useCallback(
		result => {
			// dropped outside the list
			if (!result.destination) {
				return;
			}

			const items = reorder(list.products, result.source.index, result.destination.index);

			handleUpdateOrder(result.draggableId, result.destination.index, result.source.index > result.destination.index ? 'up' : 'down');

			setList(() => ({ products: items }));
		},
		[list]
	);

	useEffect(() => {
		getProducts();
	}, [updateList]);

	return (
		<>
			<DragDropContext onDragEnd={onItemDragEnd}>
				<Droppable droppableId="droppable">
					{(provided, snapshot) => (
						<Table innerRef={provided.innerRef} striped>
							<thead>
								<tr>
									<th>&nbsp;</th>
									<th>Produto</th>
									<th>Cidade</th>
									<th>Categoria</th>
									<th>Parceiro</th>
									<th>Pontos</th>
									<th>Status</th>
									<th>&nbsp;</th>
									<th>
										<Button color="success" size="sm" onClick={handleAddBtn}>
											Adicionar
										</Button>
									</th>
								</tr>
							</thead>
							<tbody>
								{list.products.map((product, index) => (
									<Draggable key={product._id} draggableId={product._id} index={index}>
										{(provided, snapshot) => (
											<tr ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
												<th scope="row">
													<img src={moveIcon} width="20" heigth="20" />
												</th>
												<th scope="row">{product.name}</th>
												<th scope="row">{product.city ? product.city.name : '-'}</th>
												<th scope="row">{product.product_category ? product.product_category.name : '-'}</th>
												<th scope="row">{product.partner ? product.partner.name : '-'}</th>
												<th scope="row">{product.price}</th>
												<th scope="row">{product.status ? 'Ativo' : 'Inativo'}</th>
												<td>
													{product.use_coupon_list ? (
														<Button color="primary" size="sm" data-id={product._id} onClick={handleCuponsBtn}>
															Cupons
														</Button>
													) : (
														<></>
													)}
												</td>
												<td>
													<Button color="primary" size="sm" data-id={product._id} onClick={handleEditBtn}>
														Editar
													</Button>
												</td>
												<td>
													<Button color="danger" size="sm" data-id={product._id} onClick={handleRemoveBtn}>
														Remover
													</Button>
												</td>
											</tr>
										)}
									</Draggable>
								))}
								{provided.placeholder}
							</tbody>
						</Table>
					)}
				</Droppable>
			</DragDropContext>
		</>
	);
}
