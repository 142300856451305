import React, { useState, useEffect } from 'react';
import { Table, Button, Container, Row, Col, Input, Form, FormGroup, Label } from 'reactstrap';
import downloadjs from 'downloadjs';
import api from '../../components/api';
import history from '../../components/history';

import './styles.css';

export default function Users() {
  const [updateList, setUpdateList] = useState(0);

  const [list, setList] = useState({
    users: [],
  });

  const [busca, setBusca] = useState('');

  const handleInputChange = e => {
    e.persist();

    setBusca(e.target.value);
  };

  const handleAddBtn = () => {
    history.push('/users/add');
  };

  const handleEditBtn = e => {
    history.push('/users/edit', {
      id: e.target.getAttribute('data-id'),
    });
  };

  const handleHistoryBtn = e => {
    history.push('/users/history', {
      id: e.target.getAttribute('data-id'),
    });
  };

  const handleOrdersBtn = e => {
    history.push('/orders', {
      user: e.target.getAttribute('data-id'),
    });
  };

  const handlePushNotificationBtn = e => {
    history.push('/notifications/push', {
      user: e.target.getAttribute('data-id'),
    });
  };

  const handleRemoveBtn = async e => {
    if (window.confirm('Deseja realmente remover esse usuário?')) {
      await api.delete(`/users/${e.target.getAttribute('data-id')}`, {});

      setUpdateList(() => updateList + 1);
    }
  };

  const handleCSVBtn = async () => {
    const csv = await api.get('/users/export', {});

    downloadjs(csv.data, 'users.csv', 'text/csv');
  };

  useEffect(() => {
    const getUsers = async () => {
      const users = await api.get('/users', { params: { busca } });

      setList(() => ({ users: users.data }));
    };

    getUsers();
  }, [busca, updateList]);

  return (
    <>
      <div className="container my-3">
        <Row>
          <Col xs="12" sm="5">
            <Form inline>
              <FormGroup className="w-100">
                <Label for="busca">Busca</Label>
                <Input type="text" name="busca" value={busca} placeholder="Nome ou email" className="mx-2 w-75" onChange={handleInputChange} />
              </FormGroup>
            </Form>
          </Col>
          <Col xs="12" sm="5" className="divDownload">
            <Button color="success" size="sm" onClick={handleCSVBtn}>
              Baixar planilha CSV
            </Button>
          </Col>
        </Row>
      </div>

      <Table striped>
        <thead>
          <tr>
            <th>Usuários</th>
            <th>Email</th>
            <th>Telefone</th>
            <th>Condomínio</th>
            <th>Saldo</th>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
            <th>
              <Button color="success" size="sm" onClick={handleAddBtn}>
                Adicionar
              </Button>
            </th>
          </tr>
        </thead>
        <tbody>
          {list.users.map(user => (
            <tr key={user._id}>
              <th scope="row">{user.name}</th>
              <th scope="row">{user.email}</th>
              <th scope="row">{user.mobile_number}</th>
              <th scope="row">
                {user.building ? user.building.name : null} - Ap: {user.apartment} {user.building_block.name ? `- ${user.building_block.name}` : null}
              </th>
              <th scope="row">{user.balance}</th>
              <td>
                <Button color="primary" size="sm" data-id={user._id} onClick={handleOrdersBtn}>
                  Pedidos
                </Button>
              </td>
              <td>
                <Button color="primary" size="sm" data-id={user._id} onClick={handleHistoryBtn}>
                  Pontos
                </Button>
              </td>
              <td>
                <Button color="primary" size="sm" data-id={user._id} onClick={handleEditBtn}>
                  Editar
                </Button>
              </td>
              <td>
                <Button color="primary" size="sm" data-id={user._id} onClick={handlePushNotificationBtn}>
                  Enviar notificação
                </Button>
              </td>
              <td>
                <Button color="danger" size="sm" data-id={user._id} onClick={handleRemoveBtn}>
                  Remover
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}
