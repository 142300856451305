import React, { useState, useEffect } from 'react';
import { Container, Col, Form, FormGroup, Label, Input, Button, Alert, Jumbotron } from 'reactstrap';
import * as Yup from 'yup';
import api from '../../components/api';
import history from '../../components/history';

// import { Container } from './styles';

const schema = Yup.object().shape({
	name: Yup.string().required(),
});

export default function CitiesEdit({ location }) {
	const { id } = location.state;

	const [message, setMessage] = useState('');
	const [inputs, setInputs] = useState({
		name: '',
	});
	const [visible, setVisible] = useState(false);

	const onDismiss = () => setVisible(false);

	const handleCancelBtn = () => {
		history.push('/cities');
	};

	const handleInputChange = e => {
		e.persist();

		setInputs(data => ({ ...data, [e.target.name]: e.target.value }));
	};

	const handleSubmit = async e => {
		e.preventDefault();

		const formIsValid = await schema.isValid(inputs);

		if (!formIsValid) {
			setMessage('Preencha corretamente os dados do formulário');
			setVisible(true);
			return false;
		}

		const Cities = await api.put(`/cities/${id}`, inputs);

		if (Cities.data.message) {
			setMessage(Cities.data.message);
			setVisible(true);
			return false;
		}

		history.push('/cities');

		return true;
	};

	useEffect(() => {
		const exec = async () => {
			const registry = await api.get('/cities', { params: { id } });

			const { name } = registry.data;
			setInputs(() => ({ name }));
		};

		exec();
	}, [id]);

	return (
		<Container className="addCities" onSubmit={handleSubmit}>
			<Jumbotron className="my-4">
				<h5 className="display-5">Editar cidade</h5>
				<hr className="my-2" />
				<Form className="form">
					<Alert color="danger" isOpen={visible} toggle={onDismiss} fade={false}>
						{message}
					</Alert>
					<Col>
						<FormGroup>
							<Label>Cidade</Label>
							<Input type="text" name="name" value={inputs.name} onChange={handleInputChange} />
						</FormGroup>
					</Col>
					<Button color="primary">Salvar</Button>{' '}
					<Button color="secondary" type="button" onClick={handleCancelBtn}>
						Cancelar
					</Button>
				</Form>
			</Jumbotron>
		</Container>
	);
}
