import React, { useState } from "react";
import {
    Container,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    Alert,
    Jumbotron
} from "reactstrap";
import * as Yup from "yup";
import api from "../../components/api";
import history from "../../components/history";

// import { Container } from './styles';

const schema = Yup.object().shape({
    name: Yup.string().required(),
    status: Yup.boolean().required()
});

export default function ProdutcsCategoryAdd() {
    const [message, setMessage] = useState("");
    const [inputs, setInputs] = useState({
        name: "",
        status: true,
    });
    const [visible, setVisible] = useState(false);

    const onDismiss = () => setVisible(false);

    const handleCancelBtn = () => {
        history.push("/products-category");
    };

    const handleInputChange = e => {
        e.persist();

        setInputs(data => ({ ...data, [e.target.name]: e.target.value }));
    };

    const handleSubmit = async e => {
        e.preventDefault();

        const formIsValid = await schema.isValid(inputs);

        if (!formIsValid) {
            setMessage("Preencha corretamente os dados do formulário");
            setVisible(true);
            return false;
        }

      
        const productsCategory = await api.post("/product-category", inputs);

        if (productsCategory.data.message) {
            setMessage(productsCategory.data.message);
            setVisible(true);
            return false;
        }

        history.push("/products-category");

        return true;
    };

    return (
        <Container className="addProductsCategory" onSubmit={handleSubmit}>
            <Jumbotron className="my-4">
                <h5 className="display-5">Adicionar categoria</h5>
                <hr className="my-2" />
                <Form className="form">
                    <Alert
                        color="danger"
                        isOpen={visible}
                        toggle={onDismiss}
                        fade={false}
                    >
                        {message}
                    </Alert>
                    <Col>
                        <FormGroup>
                            <Label>Nome</Label>
                            <Input
                                type="text"
                                name="name"
                                value={inputs.name}
                                onChange={handleInputChange}
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label>Status</Label>
                            <Input
                                type="select"
                                name="status"
                                value={inputs.status}
                                onChange={handleInputChange}
                            >
                                <option value={false}>Inativo</option>
                                <option value>Ativo</option>
                            </Input>
                        </FormGroup>
                    </Col>
                    
                    <Button color="primary">Adicionar</Button>{" "}
                    <Button
                        color="secondary"
                        type="button"
                        onClick={handleCancelBtn}
                    >
                        Cancelar
                    </Button>
                </Form>
            </Jumbotron>
        </Container>
    );
}
