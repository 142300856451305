import React, { useState, useEffect } from "react";
import { Table, Button } from "reactstrap";
import api from "../../components/api";
import history from "../../components/history";

// import { Container } from './styles';

export default function ProductsCategory() {
    const [updateList, setUpdateList] = useState(0);

    const [list, setList] = useState({
        productsCategory: []
    });

    const getProductsCategory = async () => {
        const productsCategory = await api.get("/product-category", {});

        setList(() => ({ productsCategory: productsCategory.data }));
    };

    const handleAddBtn = () => {
        history.push("/products-category/add");
    };

    const handleEditBtn = e => {
        history.push("/products-category/edit", {
            id: e.target.getAttribute("data-id")
        });
    };

    const handleRemoveBtn = async e => {
        if (window.confirm("Deseja realmente remover essa categoria?")) {
            await api.delete(
                `/product-category/${e.target.getAttribute("data-id")}`,
                {}
            );

            setUpdateList(() => updateList + 1);
        }
    };

    useEffect(() => {
        getProductsCategory();
    }, [updateList]);

    return (
        <>
            <Table striped>
                <thead>
                    <tr>
                        <th>Categoria</th>
                        <th>Status</th>
                        <th>&nbsp;</th>
                        <th>
                            <Button
                                color="success"
                                size="sm"
                                onClick={handleAddBtn}
                            >
                                Adicionar
                            </Button>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {list.productsCategory.map(productsCategory => (
                        <tr key={productsCategory._id}>
                            <th scope="row">{productsCategory.name}</th>
                            <th scope="row">
                                {productsCategory.status ? "Ativo" : "Inativo"}
                            </th>
                            <td>
                                <Button
                                    color="primary"
                                    size="sm"
                                    data-id={productsCategory._id}
                                    onClick={handleEditBtn}
                                >
                                    Editar
                                </Button>
                            </td>
                            <td>
                                <Button
                                    color="danger"
                                    size="sm"
                                    data-id={productsCategory._id}
                                    onClick={handleRemoveBtn}
                                >
                                    Remover
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </>
    );
}
