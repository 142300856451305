import React, { useState, useEffect } from 'react';
import { Table, Button, Form, Input } from 'reactstrap';
import format from 'date-fns/format';
import api from '../../components/api';

// import { Container } from './styles';

export default function ProductsCoupons({ location }) {
	const { id } = location.state;

	const [updateList, setUpdateList] = useState(0);
	const [productName, setProductName] = useState('');
	const [qtyCoupons, setQtyCoupons] = useState(0);
	const [qtyCouponsAvailable, setQtyCouponsAvailables] = useState(0);

	const [list, setList] = useState({
		coupons: [],
	});

	const handleInputCSVFile = async e => {
		e.persist();
		const formData = new FormData();
		formData.append('product', id);
		formData.append('csv', e.target.files[0]);

		const config = {
			headers: {
				'content-type': 'multipart/form-data',
			},
		};

		try {
			const productCoupon = await api.post('/product-coupons/', formData, config);

			if (productCoupon.data.msg) {
				setUpdateList(() => updateList + 1);
				alert('CSV importado com sucesso');
				return false;
			}

			if (productCoupon.data.error) {
				setUpdateList(() => updateList + 1);
				alert('CSV com formato inválido');
				return false;
			}
		} catch (ex) {
			setUpdateList(() => updateList + 1);
			alert('CSV com formato inválido');
			return false;
		}
	};

	const handleRemoveBtn = async e => {
		if (window.confirm('Deseja realmente remover esse cupom?')) {
			await api.delete(`/product-coupons/${e.target.getAttribute('data-id')}`, {});

			setUpdateList(() => updateList + 1);
		}
	};

	const getCoupons = async () => {
		const result = await api.get(`/product-coupons/${id}`);

		setQtyCoupons(result.data.qtyCoupons);
		setQtyCouponsAvailables(result.data.qtyCouponsAvailable);
		setList(() => ({ coupons: result.data.coupons }));
	};

	const getProductinfo = async () => {
		const product = await api.get('/products', { params: { id } });

		setProductName(product.data.name);
	};

	useEffect(() => {
		getProductinfo();

		getCoupons();
	}, [updateList]);

	return (
		<>
			<Table striped>
				<thead>
					<tr>
						<th>Produto</th>
						<th>Total</th>
						<th>Disponíveis</th>
						<th>Usados / Vencidos</th>
						<th>Adicionar cupons</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<th scope="row">{productName}</th>
						<th scope="row">{qtyCoupons}</th>
						<th scope="row">{qtyCouponsAvailable}</th>
						<th scope="row">{qtyCoupons - qtyCouponsAvailable}</th>
						<th>
							<Form className="form">
								<Input type="file" name="csv" onChange={handleInputCSVFile} />
								<small>(Formato: codigo;validade)</small>
							</Form>
						</th>
					</tr>
				</tbody>
			</Table>

			<Table striped>
				<thead>
					<tr>
						<th>Código</th>
						<th>Validade</th>
						<th>Usado por</th>
						<th>&nbsp;</th>
					</tr>
				</thead>
				<tbody>
					{list.coupons.map((coupon, index) => (
						<tr>
							<th scope="row">{coupon.code}</th>
							<th scope="row">{format(new Date(new Date(coupon.validity).valueOf() + new Date(coupon.validity).getTimezoneOffset() * 60 * 1000), 'dd/MM/yyyy')}</th>
							<th scope="row">{coupon.user ? coupon.user.name : '-'}</th>
							<td>
								<Button color="danger" size="sm" data-id={coupon._id} onClick={handleRemoveBtn}>
									Remover
								</Button>
							</td>
						</tr>
					))}
				</tbody>
			</Table>
		</>
	);
}
