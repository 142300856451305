import React, { useState, useEffect } from 'react';
import { Table, Button } from 'reactstrap';
import { format } from 'date-fns';
import api from '../../components/api';
import history from '../../components/history';

// import { Container } from './styles';

export default function Partners() {
  const [updateList, setUpdateList] = useState(0);

  const [list, setList] = useState({
    partners: [],
  });

  const getPartners = async () => {
    const partners = await api.get('/partners', {});

    setList(() => ({ partners: partners.data }));
  };

  const handleAddBtn = () => {
    history.push('/partners/add');
  };

  const handleEditBtn = e => {
    history.push('/partners/edit', {
      id: e.target.getAttribute('data-id'),
    });
  };

  const handleRemoveBtn = async e => {
    if (window.confirm('Deseja realmente remover esse parceiro?')) {
      await api.delete(`/partners/${e.target.getAttribute('data-id')}`, {});

      setUpdateList(() => updateList + 1);
    }
  };

  useEffect(() => {
    getPartners();
  }, [updateList]);

  return (
    <>
      <Table striped>
        <thead>
          <tr>
            <th>Parceiro</th>
            <th>Data cadastro</th>
            <th>Envio</th>
            <th>Contato</th>
            <th>Email</th>
            <th>Status</th>
            <th>&nbsp;</th>
            <th>
              <Button color="success" size="sm" onClick={handleAddBtn}>
                Adicionar
              </Button>
            </th>
          </tr>
        </thead>
        <tbody>
          {list.partners.map(partner => (
            <tr key={partner._id}>
              <th scope="row">{partner.name}</th>
              <th scope="row">{format(new Date(partner.createdAt), 'dd/MM/yyyy')}</th>
              <th scope="row">{partner.direct_shipping ? 'Parceiro' : 'Instituto Muda'}</th>
              <th scope="row">{partner.contact_name}</th>
              <th scope="row">{partner.contact_email}</th>
              <th scope="row">{partner.status ? 'Ativo' : 'Inativo'}</th>

              <td>
                <Button color="primary" size="sm" data-id={partner._id} onClick={handleEditBtn}>
                  Editar
                </Button>
              </td>
              <td>
                <Button color="danger" size="sm" data-id={partner._id} onClick={handleRemoveBtn}>
                  Remover
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}
