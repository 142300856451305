import { takeLatest, call, put, all } from "redux-saga/effects";
import { toast } from "react-toastify";

import api from "../../../components/api";
import history from "../../../components/history";
import { signInSuccess, signFailure } from "./actions";

export function* signIn({ payload }) {
    try {
        const { email, password } = payload;

        const response = yield call(api.post, "sessions", {
            email,
            password
        });

        const { token, user } = response.data;

        if (!user.admin) {
            toast.error("Usuário não é admin");
            return;
        }

        api.defaults.headers.Authorization = `Bearer ${token}`;

        yield put(signInSuccess(token, user));

        history.push("/dashboard");
    } catch (err) {
        toast.error("Falha na autenticação");
        yield put(signFailure());
    }
}

export function setToken({ payload }) {
    if (!payload) return;

    const { token } = payload.auth;
 
    if (token) {
        api.defaults.headers.Authorization = `Bearer ${token}`;
    }
}

export function signOut() {
    history.push("/");
}

export default all([
    takeLatest("persist/REHYDRATE", setToken),
    takeLatest("@auth/SIGN_IN_REQUEST", signIn),
    takeLatest("@auth/SIGN_OUT", signOut)
]);
