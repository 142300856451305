import React, { useState, useEffect } from "react";
import {
    Container,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    Alert,
    Jumbotron
} from "reactstrap";
import * as Yup from "yup";
import api from "../../components/api";
import history from "../../components/history";

// import { Container } from './styles';

const schema = Yup.object().shape({
    name: Yup.string().required(),
    email: Yup.string()
        .email()
        .required(),
    password: Yup.string().required(),
    cpf: Yup.string().required(),
    mobile_number: Yup.string().required(),
    apartment: Yup.number().required(),
    building: Yup.string().required(),
    admin: Yup.string().required(),
    active: Yup.boolean().required()
});

export default function UsersAdd() {
    const [message, setMessage] = useState("");
    const [buildings, setBuildings] = useState([]);
    const [inputs, setInputs] = useState({
        name: "",
        email: "",
        password: "",
        cpf: "",
        mobile_number: "",
        apartment: "",
        building: "",
        admin: "",
        active: ""
    });

    const [visible, setVisible] = useState(false);

    const onDismiss = () => setVisible(false);

    const handleCancelBtn = () => {
        history.push("/users");
    };

    const handleInputChange = e => {
        e.persist();
        setInputs(data => ({ ...data, [e.target.name]: e.target.value }));
    };

    const handleSubmit = async e => {
        e.preventDefault();

        const formIsValid = await schema.isValid(inputs);

        if (!formIsValid) {
            setMessage("Preencha corretamente os dados do formulário");
            setVisible(true);
            return false;
        }

        const user = await api.post("/users", inputs);

        if (user.data.error) {
            setMessage(user.data.error);
            setVisible(true);
            return false;
        }

        history.push("/users");

        return true;
    };

    useEffect(() => {
        const exec = async () => {
            const buildingsList = await api.get("/buildings");
            setBuildings(() => buildingsList.data);
        };

        exec();
    }, []);

    return (
        <Container className="addUser" onSubmit={handleSubmit}>
            <Jumbotron className="my-4">
                <h5 className="display-5">Adicionar usuário</h5>
                <hr className="my-2" />
                <Form className="form">
                    <Alert
                        color="danger"
                        isOpen={visible}
                        toggle={onDismiss}
                        fade={false}
                    >
                        {message}
                    </Alert>
                    <Col>
                        <FormGroup>
                            <Label>Nome</Label>
                            <Input
                                type="text"
                                name="name"
                                value={inputs.name}
                                onChange={handleInputChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Email</Label>
                            <Input
                                type="email"
                                name="email"
                                value={inputs.email}
                                onChange={handleInputChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Senha</Label>
                            <Input
                                type="password"
                                name="password"
                                value={inputs.password}
                                onChange={handleInputChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>CPF</Label>
                            <Input
                                type="text"
                                name="cpf"
                                value={inputs.cpf}
                                onChange={handleInputChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Celular</Label>
                            <Input
                                type="text"
                                name="mobile_number"
                                value={inputs.mobile_number}
                                onChange={handleInputChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Apartamento</Label>
                            <Input
                                type="text"
                                name="apartment"
                                value={inputs.apartment}
                                onChange={handleInputChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Condomínio</Label>
                            <Input
                                type="select"
                                name="building"
                                value={inputs.building}
                                onChange={handleInputChange}
                            >
                                <option value="">Escolha...</option>
                                {buildings.map(item => (
                                    <option value={item._id} key={item._id}>
                                        {item.name}
                                    </option>
                                ))}
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label>Nivel de acesso</Label>
                            <Input
                                type="select"
                                name="admin"
                                value={inputs.admin}
                                onChange={handleInputChange}
                            >
                                <option value={false}>Usuário</option>
                                <option value>Admin</option>
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label>Status</Label>
                            <Input
                                type="select"
                                name="active"
                                value={inputs.active}
                                onChange={handleInputChange}
                            >
                                <option value={false}>Inativo</option>
                                <option value>Ativo</option>
                            </Input>
                        </FormGroup>
                    </Col>
                    <Button color="primary">Adicionar</Button>{" "}
                    <Button
                        color="secondary"
                        type="button"
                        onClick={handleCancelBtn}
                    >
                        Cancelar
                    </Button>
                </Form>
            </Jumbotron>
        </Container>
    );
}
