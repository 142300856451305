import React, { useState, useEffect } from 'react';
import { Table, Button } from 'reactstrap';
import { format } from 'date-fns';
import api from '../../components/api';
import history from '../../components/history';

export default function Orders({ location }) {
  const { user } = location.state ? location.state : { user: null };

  const [list, setList] = useState({
    orders: [],
  });

  const orderStatusNames = {
    processing: 'Processando',
    sended: 'Enviado',
    canceled: 'Cancelado',
  };

  const handleEditBtn = e => {
    history.push('/orders/edit', {
      id: e.target.getAttribute('data-id'),
    });
  };

  useEffect(() => {
    const getOrders = async () => {
      const orders = await api.get('/orders', { params: { user } });

      setList(() => ({ orders: orders.data }));
    };

    getOrders();
  }, [user]);

  return (
    <>
      <Table striped>
        <thead>
          <tr>
            <th>Data</th>
            <th>Pedido</th>
            <th>Cliente</th>
            <th>Condomínio</th>
            <th>Status</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {list.orders.map(order => (
            <tr key={order._id}>
              <th scope="row">{format(new Date(order.createdAt), 'dd/MM/yyyy H:mm')}</th>
              <th scope="row">{order.product ? order.product.name : '-'}</th>
              <th scope="row">{order.user.name}</th>
              <th scope="row">
                {order.user.building ? order.user.building.name : '-'} - Ap: {order.user.apartment} {order.user.building_block && order.user.building_block.name ? `- ${order.user.building_block.name}` : null}
              </th>
              <th scope="row">{orderStatusNames[order.status]}</th>
              <td>
                <Button color="primary" size="sm" data-id={order._id} onClick={handleEditBtn}>
                  Editar
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}
