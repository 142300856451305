import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Col, Form, FormGroup, Label, Input, Button, Alert, Jumbotron } from 'reactstrap';
import * as Yup from 'yup';
import { signInRequest } from '../../store/modules/auth/actions';
import logo from '../../assets/logo.png';

import './styles.css';

const schema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required(),
  password: Yup.string().required(),
});

export default function SignIn() {
  const dispatch = useDispatch();
  const loading = useSelector(state => state.auth.loading);

  const [visible, setVisible] = useState(false);

  const onDismiss = () => setVisible(false);

  const [inputs, setInputs] = useState({
    email: '',
    password: '',
  });

  const [message, setMessage] = useState('');

  const handleInputChange = e => {
    e.persist();
    setInputs(data => ({ ...data, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async e => {
    e.preventDefault();

    const formIsValid = await schema.isValid(inputs);

    if (!formIsValid) {
      setMessage('Preencha corretamente os dados do formulário');
      setVisible(true);
      return false;
    }

    const { email, password } = inputs;
    dispatch(signInRequest(email, password));

    return true;
  };

  return (
    <Container className="signIn" onSubmit={handleSubmit}>
      <Jumbotron className="my-4">
        <h5 className="display-5 text-center">
          <img src={logo} alt="Instituto Muda" />
        </h5>
        <Form className="form">
          <Alert color="danger" isOpen={visible} toggle={onDismiss} fade={false}>
            {message}
          </Alert>
          <Col>
            <FormGroup>
              <Label>Email</Label>
              <Input type="email" name="email" value={inputs.email} onChange={handleInputChange} />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label for="examplePassword">Senha</Label>
              <Input type="password" name="password" value={inputs.password} onChange={handleInputChange} />
            </FormGroup>
          </Col>
          <Button>{loading ? 'Carregando...' : 'Entrar'}</Button>
        </Form>
      </Jumbotron>
    </Container>
  );
}
