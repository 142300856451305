import React, { useState, useEffect } from 'react';
import { Container, Col, Form, FormGroup, Label, Input, Button, Alert, Jumbotron } from 'reactstrap';
import * as Yup from 'yup';
import api from '../../components/api';
import history from '../../components/history';

// import { Container } from './styles';

const schema = Yup.object().shape({
  name: Yup.string().required(),
  price: Yup.number().required(),
  status: Yup.boolean().required(),
  partner: Yup.string().required(),
  product_category: Yup.string().required(),
  city: Yup.string().required(),
});

export default function ProdutcsAdd() {
  const [message, setMessage] = useState('');
  const [partners, setPartners] = useState([]);
  const [categories, setCategories] = useState([]);
  const [cities, setCities] = useState([]);
  const [inputs, setInputs] = useState({
    name: '',
    price: '',
    status: true,
    photo: '',
    partner: '',
    product_category: '',
    city: '',
    description: '',
    use_coupon_list: false,
  });
  const [visible, setVisible] = useState(false);

  const onDismiss = () => setVisible(false);

  const handleCancelBtn = () => {
    history.push('/products');
  };

  const handleInputChange = e => {
    e.persist();
    if (e.target.type === 'file') {
      setInputs(data => ({
        ...data,
        [e.target.name]: e.target.files[0],
      }));
      return;
    }

    setInputs(data => ({ ...data, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async e => {
    e.preventDefault();

    const formIsValid = await schema.isValid(inputs);

    if (!formIsValid) {
      setMessage('Preencha corretamente os dados do formulário');
      setVisible(true);
      return false;
    }

    const formData = new FormData();
    formData.append('name', inputs.name);
    formData.append('description', inputs.description);
    formData.append('price', inputs.price);
    formData.append('status', inputs.status);
    formData.append('use_coupon_list', inputs.use_coupon_list);
    formData.append('partner', inputs.partner);
    formData.append('product_category', inputs.product_category);
    formData.append('city', inputs.city);
    if (inputs.photo) formData.append('photo', inputs.photo);

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };

    const product = await api.post('/products', formData, config);

    if (product.data.message) {
      setMessage(product.data.message);
      setVisible(true);
      return false;
    }

    history.push('/products');

    return true;
  };

  useEffect(() => {
    const exec = async () => {
      const partnersList = await api.get('/partners');
      setPartners(() => partnersList.data);

      const categoriesList = await api.get('/product-category');
      setCategories(() => categoriesList.data);

      const cityList = await api.get('/cities');
      setCities(() => cityList.data);
    };

    exec();
  }, []);

  return (
    <Container className="addProduct" onSubmit={handleSubmit}>
      <Jumbotron className="my-4">
        <h5 className="display-5">Adicionar produto</h5>
        <hr className="my-2" />
        <Form className="form">
          <Alert color="danger" isOpen={visible} toggle={onDismiss} fade={false}>
            {message}
          </Alert>
          <Col>
            <FormGroup>
              <Label>Nome</Label>
              <Input type="text" name="name" value={inputs.name} onChange={handleInputChange} />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>Instruções (será no email para o usuário)</Label>
              <Input type="textarea" name="description" value={inputs.description} onChange={handleInputChange} />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>Cidade</Label>
              <Input type="select" name="city" value={inputs.city} onChange={handleInputChange}>
                <option value="">Escolha...</option>
                {cities.map(item => (
                  <option value={item._id} key={item._id}>
                    {item.name}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>Categoria</Label>
              <Input type="select" name="product_category" value={inputs.product_category} onChange={handleInputChange}>
                <option value="">Escolha...</option>
                {categories.map(item => (
                  <option value={item._id} key={item._id}>
                    {item.name}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>Parceiro</Label>
              <Input type="select" name="partner" value={inputs.partner} onChange={handleInputChange}>
                <option value="">Escolha...</option>
                {partners.map(item => (
                  <option value={item._id} key={item._id}>
                    {item.name}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>Pontos</Label>
              <Input type="text" name="price" value={inputs.price} onChange={handleInputChange} />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>Usar lista de cupons</Label>
              <Input type="select" name="use_coupon_list" value={inputs.use_coupon_list} onChange={handleInputChange}>
                <option value={false}>Não</option>
                <option value>Sim</option>
              </Input>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>Status</Label>
              <Input type="select" name="status" value={inputs.status} onChange={handleInputChange}>
                <option value={false}>Inativo</option>
                <option value>Ativo</option>
              </Input>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>
                Foto <small>(Tamanho ideal: 650x650 pixels)</small>
              </Label>
              <Input type="file" name="photo" onChange={handleInputChange} />
            </FormGroup>
          </Col>
          <Button color="primary">Adicionar</Button>{' '}
          <Button color="secondary" type="button" onClick={handleCancelBtn}>
            Cancelar
          </Button>
        </Form>
      </Jumbotron>
    </Container>
  );
}
