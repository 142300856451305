import React, { useState } from 'react';
import { Container, Col, Form, FormGroup, Label, Input, Button, Alert, Jumbotron } from 'reactstrap';
import api from '../../components/api';
import history from '../../components/history';

export default function BuildingsAnnouncement() {
	const [message, setMessage] = useState('');
	const [inputs, setInputs] = useState({
		announcement: null,
	});

	const [visible, setVisible] = useState(false);

	const onDismiss = () => setVisible(false);

	const handleCancelBtn = () => {
		history.push('/buildings');
	};

	const handleInputChange = e => {
		e.persist();
		if (e.target.type === 'file') {
			setInputs(data => ({
				...data,
				[e.target.name]: e.target.files[0],
			}));
			return;
		}

		setInputs(data => ({ ...data, [e.target.name]: e.target.value }));
	};

	const handleSubmit = async e => {
		e.preventDefault();

		if (!inputs.announcement) {
			setMessage('Você deve enviar um arquivo para o comunicado');
			setVisible(true);
			return false;
		}

		const formData = new FormData();
		if (inputs.announcement) formData.append('announcement', inputs.announcement);
		const config = {
			headers: {
				'content-type': 'multipart/form-data',
			},
		};
		const building = await api.post(`/buildings/announcement`, formData, config);

		if (building.data.message) {
			setMessage(building.data.message);
			setVisible(true);
			return false;
		}

		history.push('/buildings');

		return true;
	};

	return (
		<Container className="addAnnouncement" onSubmit={handleSubmit}>
			<Jumbotron className="my-4">
				<h5 className="display-5">Publicar comunicado em todos os condomínios</h5>
				<hr className="my-2" />
				<Form className="form">
					<Alert color="danger" isOpen={visible} toggle={onDismiss} fade={false}>
						{message}
					</Alert>
					<Col>
						<FormGroup>
							<Label>Comunicado</Label>
							<Input type="file" name="announcement" onChange={handleInputChange} />
						</FormGroup>
					</Col>
					<Button color="primary">Salvar</Button>{' '}
					<Button color="secondary" type="button" onClick={handleCancelBtn}>
						Cancelar
					</Button>
				</Form>
			</Jumbotron>
		</Container>
	);
}
