import React, { useState, useEffect } from 'react';
import { Container, Col, Form, FormGroup, Label, Input, Button, Alert, Jumbotron } from 'reactstrap';
import * as Yup from 'yup';
import api from '../../components/api';
import history from '../../components/history';

// import { Container } from './styles';

const schema = Yup.object().shape({
  name: Yup.string().required(),
  cnpj: Yup.string().required(),
  max_apartments: Yup.number().required(),
  city: Yup.string().required(),
});

export default function BuildingsAdd() {
  const [message, setMessage] = useState('');
  const [cities, setCities] = useState([]);
  const [inputs, setInputs] = useState({
    name: '',
    cnpj: '',
    max_apartments: '',
    add_block: '',
    city: '',
  });
  const [list, setList] = useState({
    building_blocks: [],
  });
  const [visible, setVisible] = useState(false);

  const onDismiss = () => setVisible(false);

  const handleCancelBtn = () => {
    history.push('/buildings');
  };

  const handleAddBlockClick = () => {
    setList(() => ({
      building_blocks: [...new Set([...list.building_blocks, inputs.add_block])],
    }));
    setInputs(data => ({ ...data, add_block: '' }));
  };

  const handleInputChange = e => {
    e.persist();
    setInputs(data => ({ ...data, [e.target.name]: e.target.value }));
  };

  const handleDeleteBlock = e => {
    e.persist();

    setList(() => ({
      building_blocks: [...list.building_blocks.filter(value => value !== e.target.getAttribute('block_val'))],
    }));
  };

  const handleSubmit = async e => {
    e.preventDefault();

    const formIsValid = await schema.isValid(inputs);

    if (!formIsValid) {
      setMessage('Preencha corretamente os dados do formulário');
      setVisible(true);
      return false;
    }

    const building = await api.post('/buildings', {
      ...inputs,
      blocks: list.building_blocks.join(','),
    });

    if (building.data.message) {
      setMessage(building.data.message);
      setVisible(true);
      return false;
    }

    history.push('/buildings');

    return true;
  };

  useEffect(() => {
    const exec = async () => {
      const cityList = await api.get('/cities');
      setCities(() => cityList.data);
    };

    exec();
  }, []);

  return (
    <Container className="addBuilding" onSubmit={handleSubmit}>
      <Jumbotron className="my-4">
        <h5 className="display-5">Adicionar condomínio</h5>
        <hr className="my-2" />
        <Form className="form">
          <Alert color="danger" isOpen={visible} toggle={onDismiss} fade={false}>
            {message}
          </Alert>
          <Col>
            <FormGroup>
              <Label>Nome</Label>
              <Input type="text" name="name" value={inputs.name} onChange={handleInputChange} />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>CNPJ</Label>
              <Input type="text" name="cnpj" value={inputs.cnpj} onChange={handleInputChange} />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>Cidade</Label>
              <Input type="select" name="city" value={inputs.city} onChange={handleInputChange}>
                <option value="">Escolha...</option>
                {cities.map(item => (
                  <option value={item._id} key={item._id}>
                    {item.name}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>Quantidade de apartamentos por bloco</Label>
              <Input type="text" name="max_apartments" value={inputs.max_apartments} onChange={handleInputChange} />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>Bloco</Label>
              <Input type="text" name="add_block" value={inputs.add_block} onChange={handleInputChange} />
              <Button color="primary" type="button" onClick={handleAddBlockClick}>
                Adicionar
              </Button>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              {list.building_blocks.map(block => (
                <p key={block}>
                  <Button color="secondary" type="button" onClick={handleDeleteBlock} block_val={block}>
                    x
                  </Button>
                  {block}
                </p>
              ))}
            </FormGroup>
          </Col>
          <Button color="primary">Adicionar</Button>{' '}
          <Button color="secondary" type="button" onClick={handleCancelBtn}>
            Cancelar
          </Button>
        </Form>
      </Jumbotron>
    </Container>
  );
}
