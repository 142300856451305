import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './RouteWrapper';

import SignIn from '../pages/SignIn';
import Dashboard from '../pages/Dashboard';

import Buildings from '../pages/Buildings';
import BuildingsAdd from '../pages/Buildings/add';
import BuildingsEdit from '../pages/Buildings/edit';
import BuildingsAnnouncement from '../pages/Buildings/announcement';

import Users from '../pages/Users';
import UsersAdd from '../pages/Users/add';
import UsersEdit from '../pages/Users/edit';
import UsersHistory from '../pages/Users/history';

import Partners from '../pages/Partners';
import PartnersAdd from '../pages/Partners/add';
import PartnersEdit from '../pages/Partners/edit';

import Products from '../pages/Products';
import ProductsAdd from '../pages/Products/add';
import ProductsEdit from '../pages/Products/edit';
import ProductsCoupons from '../pages/Products/coupons';

import ProductsCategory from '../pages/ProductsCategory';
import ProductsCategoryAdd from '../pages/ProductsCategory/add';
import ProductsCategoryEdit from '../pages/ProductsCategory/edit';

import Cities from '../pages/Cities';
import CitiesAdd from '../pages/Cities/add';
import CitiesEdit from '../pages/Cities/edit';

import Orders from '../pages/Orders';
import OrdersEdit from '../pages/Orders/edit';

import NotificationsPush from '../pages/NotificationsPush';
import NotificationsPushScheduled from '../pages/NotificationsPushScheduled';

export default function Routes() {
	return (
		<Switch>
			<Route exact path="/" component={SignIn} />
			<Route path="/dashboard" component={Dashboard} isPrivate />
			<Route exact path="/buildings" component={Buildings} isPrivate />
			<Route exact path="/buildings/add" component={BuildingsAdd} isPrivate />
			<Route exact path="/buildings/edit" component={BuildingsEdit} isPrivate />
			<Route exact path="/buildings/announcement" component={BuildingsAnnouncement} isPrivate />
			<Route exact path="/users" component={Users} isPrivate />
			<Route exact path="/users/add" component={UsersAdd} isPrivate />
			<Route exact path="/users/edit" component={UsersEdit} isPrivate />
			<Route exact path="/users/history" component={UsersHistory} isPrivate />

			<Route exact path="/partners" component={Partners} isPrivate />
			<Route exact path="/partners/add" component={PartnersAdd} isPrivate />
			<Route exact path="/partners/edit" component={PartnersEdit} isPrivate />

			<Route exact path="/products-category" component={ProductsCategory} isPrivate />
			<Route exact path="/products-category/add" component={ProductsCategoryAdd} isPrivate />
			<Route exact path="/products-category/edit" component={ProductsCategoryEdit} isPrivate />

			<Route exact path="/cities" component={Cities} isPrivate />
			<Route exact path="/cities/add" component={CitiesAdd} isPrivate />
			<Route exact path="/cities/edit" component={CitiesEdit} isPrivate />

			<Route exact path="/products" component={Products} isPrivate />
			<Route exact path="/products/add" component={ProductsAdd} isPrivate />
			<Route exact path="/products/edit" component={ProductsEdit} isPrivate />
			<Route exact path="/products/coupons" component={ProductsCoupons} isPrivate />

			<Route exact path="/orders" component={Orders} isPrivate />
			<Route exact path="/orders/edit" component={OrdersEdit} isPrivate />

			<Route exact path="/notifications/push" component={NotificationsPush} isPrivate />
			<Route exact path="/notifications/push/scheduled" component={NotificationsPushScheduled} isPrivate />
		</Switch>
	);
}
