import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink } from 'reactstrap';
import { signOut } from '../../store/modules/auth/actions';
import logo from '../../assets/logo.png';

const AdmNavbar = ({ location }) => {
	const dispatch = useDispatch();

	const [isOpen, setIsOpen] = useState(false);

	const toggle = () => setIsOpen(!isOpen);

	function hanbleSignOut() {
		dispatch(signOut());
	}

	return (
		<div>
			<Navbar color="light" light expand="md">
				<NavbarBrand href="/">
					<img src={logo} alt="Instituto Muda" />
				</NavbarBrand>
				<NavbarToggler onClick={toggle} />
				<Collapse isOpen={isOpen} navbar>
					<Nav className="ml-auto" navbar>
						<NavItem>
							<NavLink href="/orders" active={location.pathname === '/orders'}>
								Pedidos
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink href="/users" active={location.pathname === '/users'}>
								Usuários
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink href="/buildings" active={location.pathname === '/buildings'}>
								Condomínios
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink href="/products" active={location.pathname === '/products'}>
								Produtos
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink href="/products-category" active={location.pathname === '/products-category'}>
								Categorias de Produtos
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink href="/partners" active={location.pathname === '/partners'}>
								Parceiros
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink href="/cities" active={location.pathname === '/cities'}>
								Cidades
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink href="/notifications/push/scheduled" active={location.pathname === '/notifications/push/scheduled'}>
								Notificações
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink href="#" onClick={hanbleSignOut}>
								Sair
							</NavLink>
						</NavItem>
					</Nav>
				</Collapse>
			</Navbar>
		</div>
	);
};

export default withRouter(AdmNavbar);
