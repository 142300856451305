import React from 'react';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import './components/ReactotronConfig';

import Routes from './components/routes';
import history from './components/history';

import { store, persistor } from './store';

import GlobalStyles from './layouts/global';

function App() {
	return (
		<Provider store={store}>
			<PersistGate persistor={persistor}>
				<Router history={history}>
					<Routes />
					<GlobalStyles />
					<ToastContainer autoClose={3000} />
				</Router>
			</PersistGate>
		</Provider>
	);
}

export default App;
